import React, { useState, useEffect } from 'react';
import { getAllEvents, deleteEvent } from '../services/EventService';
import { Modal, Button } from 'react-bootstrap'; // Import necessary components
import EventForm  from '../components/forms/EventForm';

const EventList = () => {
    const [events, setEvents] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);  // State to handle modal visibility
    const [selectedEvent, setSelectedEvent] = useState(null);

    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchEvents = async () => {
        try {
            const response = await getAllEvents();
            console.log('getting all events');
            console.log(response.data);
            setEvents(response.data);

        } catch (error) {
            console.error('Error fetching events:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteEvent(id);
            console.log('event deleted! refreshing list');
            fetchEvents(); // Refresh the list after deletion
        } catch (error) {
            console.error('Error deleting event:', error);
        }
    };

    const handleEdit = (event) => {
        setSelectedEvent(event);  // Set the selected event to state
        setShowEditModal(true);   // Show the modal
    };

    const handleCloseModal = () => {
        setShowEditModal(false);  // Hide the modal when closed
    };

    return (
        <div className="container-fluid">
            <div className="row text-center">
                <h1 className="text-center">Events</h1>
            </div>
            <div className="row">
                {events.length === 0 ? (
                    <p>No events found.</p>
                ) : (
                    <ul className="row">
                        {events.map((event, index) => (
                            <li key={index} className="card col-4">
                                <div className="card-header">
                                    <div className="container text-center">
                                        <div className="row justify-content-end">
                                            <div className="col-3">
                                                <button className="btn btn-danger"
                                                        onClick={() => handleDelete(event.id)}>Delete
                                                </button>
                                            </div>
                                            <div className="col-3">
                                                <button className="btn btn-warning"
                                                        onClick={() => handleEdit(event)}>Edit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <img src={event.photoUrl} alt="event" className="card-img-top"/>
                                <div className="card-body">
                                    <h3>{event.eventName}</h3>
                                    <p>{event.eventDate}</p>
                                    <p>{event.emailMessage}</p>
                                </div>
                                <div className="card-footer">
                                    <p>Sending To:</p>
                                    <ul>
                                        {event.contacts.map((contact, index) => (
                                            <li key={index}>{contact.name} ({contact.email})</li>
                                        ))}
                                    </ul>
                                </div>
                            </li>
                            ))}
                    </ul>
                )}
            </div>
            {/* Modal for Editing Event */}
            <Modal show={showEditModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedEvent && (<EventForm event={selectedEvent} /> )}
                </Modal.Body>
                <Modal.Footer>
                    {/*<Button variant="secondary" onClick={handleCloseModal}>*/}
                    {/*    Close*/}
                    {/*</Button>*/}
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default EventList;
