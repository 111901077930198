import React, { useState, useEffect } from 'react';
import { createContact, updateContact } from '../../services/ContactService';

const ContactForm = ({ contact, onClose }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    useEffect(() => {
        if (contact) {
            setName(contact.name);
            setEmail(contact.email);
            setPhoneNumber(contact.phoneNumber);
        }
    }, [contact]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const contactData = { name, email, phoneNumber };

        try {
            if (contact) {
                await updateContact(contact.id, contactData);
                alert('Contact updated successfully!');
            } else {
                await createContact(contactData);
                alert('Contact created successfully!');
            }
            onClose(); // Close the modal after saving
        } catch (error) {
            console.error('Error saving contact:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label className="form-label">Name:</label>
                <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </div>
            <div className="mb-3">
                <label className="form-label">Email:</label>
                <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>
            <div className="mb-3">
                <label className="form-label">Phone Number:</label>
                <input
                    type="text"
                    className="form-control"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
            </div>
            <button type="submit" className="btn btn-primary">
                {contact ? 'Update Contact' : 'Create Contact'}
            </button>
        </form>
    );
};

export default ContactForm;