import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

const NavBar = ({ isLoggedIn, handleLogout }) => {
    const navigate = useNavigate();

    const handleLogoutClick = () => {
        handleLogout();  // Call the passed-in logout handler
        navigate('/login');  // Redirect to login page after logout
    };

    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {isLoggedIn && (
                    <>
                        <li>
                            <NavLink to="/events">Events</NavLink>
                        </li>
                        <li>
                            <NavLink to="/contacts">Contacts</NavLink>
                        </li>
                    </>
                )}
                {isLoggedIn ? (
                    <li>
                        <button onClick={handleLogoutClick}>Logout</button>
                    </li>
                ) : (
                    <>
                        <li>
                            <NavLink to="/login">Login</NavLink>
                        </li>
                        <li>
                            <NavLink to="/register">Register</NavLink>
                        </li>
                    </>
                )}
            </ul>
        </nav>
    );
};

export default NavBar;