import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const API_URL = `${API_BASE_URL}/api/contacts`; // Adjust for your backend

// Add a request interceptor to automatically add the Authorization header
axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('jwt'); // Get the token from localStorage

        if (token) {
            // If the token exists, add it to the Authorization header
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const getUserContacts = async () => {
    return await axios.get(API_URL);
};

export const createContact = async (contactData) => {
    return await axios.post(API_URL, contactData);
};

export const updateContact = async (id, contactData) => {
    return await axios.put(`${API_URL}/${id}`, contactData);
};

export const deleteContact = async (id) => {
    return await axios.delete(`${API_URL}/${id}`);
};
