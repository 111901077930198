import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import Events from './pages/Events';
import Contacts from './pages/Contacts';
import EventForm from './components/forms/EventForm';
import LoginForm from './components/forms/auth/LoginForm';
import RegisterForm from './components/forms/auth/RegisterForm';
import PrivateRoute from "./PrivateRoute";
import {jwtDecode} from 'jwt-decode';
import NavBar from './components/NavBar';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    // const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('jwt');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);

                // Get the current time and compare it to the token expiration time
                const currentTime = Date.now() / 1000; // in seconds
                if (decodedToken.exp < currentTime) {
                    // Token has expired, remove it from localStorage
                    console.log('Token expired');
                    localStorage.removeItem('jwt');
                    setIsLoggedIn(false);
                    // navigate('/login');  // Redirect to login page
                } else {
                    // Token is valid
                    console.log('token is valid')
                    setIsLoggedIn(true);
                }
            } catch (error) {
                console.error('Error decoding token:', error);
                setIsLoggedIn(false);
            }
        } else {
            setIsLoggedIn(false);  // No token means user is not logged in
        }  // If token exists, user is logged in
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('jwt');  // Remove token on logout
        setIsLoggedIn(false);
    };
    return (
        <BrowserRouter>
            <div className="App">
                <NavBar isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
                <Routes>
                    <Route path="/login" element={<LoginForm />} />
                    <Route path="/register" element={<RegisterForm />} />
                    <Route path="/events" element={<PrivateRoute element={Events} isLoggedIn={isLoggedIn} />} />
                    <Route path="/contacts" element={<PrivateRoute element={Contacts} isLoggedIn={isLoggedIn} />} />
                    <Route path="/create-event" element={<PrivateRoute element={EventForm} isLoggedIn={isLoggedIn} />} />
                </Routes>
            </div>
        </BrowserRouter>
    );
};

export default App;
