import React, { useState } from 'react';
import EventList from '../components/EventList';
import EventForm from '../components/forms/EventForm';
import { Modal, Button } from 'react-bootstrap';

const Events = () => {
    const [showModal, setShowModal] = useState(false);

    // Functions to open and close the modal
    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <div className="container-fluid">
            <div className="container text-center">
                <div className="row justify-content-end">
                    <div className="col-3">
                        <Button variant="primary" onClick={handleOpenModal}>
                            Create New Event
                        </Button>
                    </div>
                </div>

            </div>
            {/* Modal for Event Creation */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Create a New Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EventForm />  {/* Embed EventForm in the modal */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <EventList />  {/* Display the list of events */}
        </div>
    );
};

export default Events;