import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_URL;

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_BASE_URL }/auth/login`, {
                email: email,
                password: password,
            });

            console.log('response', response);
            //
            // // Save the JWT token in localStorage
            // localStorage.setItem("jwt", response.data.jwt);

            // The response data should contain the token and expiration time
            if (response.data && response.data.token) {
                console.log('JWT Token:', response.data.token);
                console.log('Expires In:', response.data.expiresIn);

                // Store the token in localStorage (or use it directly in your app)
                localStorage.setItem('jwt', response.data.token);

                // You can also store the expiration time if needed
                localStorage.setItem('expiresIn', response.data.expiresIn);

                // Redirect or handle the post-login flow here
                // Redirect the user to the events page or dashboard
                navigate("/events");
            }
        } catch (error) {
            setError("Invalid email or password");
        }
    };

    return (
        <div>
            <h2>Login</h2>
            <form onSubmit={handleLogin}>
                <div>
                    <label>Email</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Password</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Login</button>
                {error && <p style={{ color: "red" }}>{error}</p>}
            </form>
        </div>
    );
};

export default Login;