import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const registerUser = async (userData) => {
    return await axios.post(`${API_URL}/auth/register`, userData, {
        headers: {
            'Content-Type': 'application/json',  // Ensures the data is sent in JSON format
            // Add any other headers you might need here
        }
    });
};