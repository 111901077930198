import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const API_URL = `${API_BASE_URL}/api/events`; // Adjust for your backend

// Add a request interceptor to automatically add the Authorization header
axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('jwt'); // Get the token from localStorage

        if (token) {
            // If the token exists, add it to the Authorization header
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const getAllEvents = async () => {
    return await axios.get(API_URL);
};

export const getEventById = async (id) => {
    return await axios.get(`${API_URL}/${id}`);
};

export const createEvent = async (formData) => {
    try {
        const response = await axios.post(API_URL, formData, {
            headers: {
                // Axios will handle the multipart Content-Type automatically,
                // no need to set it manually
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error creating event:', error);
        throw error;
    }
};

export const updateEvent = async (id, event) => {
    return await axios.put(`${API_URL}/${id}`, event);
};

export const deleteEvent = async (id) => {
    return await axios.delete(`${API_URL}/${id}`);
};
